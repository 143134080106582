<template>
  <ListPage
    :gridData="filteredData"
    :gridConfig="gridConfig"
    :filters="filters"
  />
</template>

<script>
import { mapGetters } from "vuex";
import ListPage from "@/components/penal-retail-helpers/ListPage";

import zonesFormConfig from "@/helpers/zonesFormConfig";

let collectionName = "zones";

export default {
  components: {
    ListPage,
  },
  computed: {
    ...mapGetters(["allCustomers", "allEstablishments", "allZones"]),
    filteredData() {
      let filteredData = this.allZones;

      ["customer"].forEach((a) => {
        if (this.appliedFilters[a]) {
          filteredData = filteredData.filter(
            (x) => x[a].id === this.appliedFilters[a].id
          );
        }
      });

      return filteredData;
    },
  },
  data() {
    let attributes = zonesFormConfig(
      this,
      this.$store.state.AppActiveUser,
      true
    );

    let columns = [];
    columns.push({
      headerName: this.$t(`${collectionName}.columns.alias`),
      filter: false,
      width: 100,
      field: "alias",
    });
    if (
      !["supercustomer", "customer"].includes(
        this.$store.state.AppActiveUser.role
      )
    ) {
      columns.push({
        headerName: this.$t(`${collectionName}.columns.customer`),
        filter: false,
        width: 100,
        field: "customer.alias",
      });
    }
    columns.push({
      headerName: this.$t(`${collectionName}.columns.establishments`),
      filter: false,
      width: 100,
      field: "establishments",
      valueFormatter: (data) => {
        const count = (data.value || []).length;
        return this.$tc(`zones.labels.establishmentsCount`, count, { count });
      },
    });

    let filters = [];
    filters.push({
      size: "1/5",
      mobileSize: "1/2",
      extraClasses: "mb-5",
      search: true,
    });

    const canFilterByCustomer = ["admin", "manager"].includes(
      this.$store.state.AppActiveUser.role
    );
    if (canFilterByCustomer) {
      filters.push({
        size: "1/5",
        mobileSize: "1/2",
        select: true,
        placeholder: `${collectionName}.placeholders.customer`,
        label: "alias",
        clearable: true,
        resetOnOptionsChange: true,
        onChange: (e) => {
          this.updateFilters("customer", e);
        },
        options: () => this.allCustomers,
      });
    }
    const model = { customer: null };
    if (["supercustomer"].includes(this.$store.state.AppActiveUser.role)) {
      model.customer = this.$store.state.AppActiveUser.customer;
    }
    filters.push({
      size: canFilterByCustomer ? "3/5" : "4/5",
      mobileSize: "1/2",
      sidebar: true,
      i18n: `${collectionName}.buttons.new`,
      extraClasses: "text-right",
      sidebarConfig: {
        title: `${collectionName}.buttons.new`,
        collectionName: collectionName,
        attributes: attributes,
        model,
        prevalidate: async (model) => {
          if (
            ["supercustomer"].includes(this.$store.state.AppActiveUser.role)
          ) {
            model.customer = this.$store.state.AppActiveUser.customer;
          }

          return false;
        },
      },
    });

    return {
      appliedFilters: {
        customer: null,
      },
      filters,
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs: columns,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: this.onRowClicked,
      },
    };
  },
  methods: {
    updateFilters(key, value) {
      if (this.appliedFilters[key] !== value) {
        this.appliedFilters[key] = value;
      }
    },
    onRowClicked(params) {
      this.$router
        .push(`/settings/${collectionName}/${params.data.id}`)
        .catch(() => {});
    },
  },
};
</script>
